import { lazy, Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoadingScreen from "../../../components/LoadingScreen.js";
import { decryptData } from "src/utils/decryptcookie";
import DashboardLayout from "../../../layouts/dashboard";
import AuthGuard from "../../../guards/AuthGuard";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const EnquiryTable = Loadable(lazy(() => import("../../../pages/EnquiryTable")));
const DemoTable = Loadable(lazy(() => import("../../../pages/DemoTable")));

const GeneralApp = Loadable(
  lazy(() => import("../../../pages/dashboard/GeneralApp"))
);
const GeneralEcommerce = Loadable(
  lazy(() => import("../../../pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("../../../pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
  lazy(() => import("../../../pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
  lazy(() => import("../../../pages/dashboard/GeneralBooking"))
);
const EcommerceShop = Loadable(
  lazy(() => import("../../../pages/dashboard/EcommerceShop"))
);
const EcommerceProductDetails = Loadable(
  lazy(() => import("../../../pages/dashboard/EcommerceProductDetails"))
);
const EcommerceProductList = Loadable(
  lazy(() => import("../../../pages/dashboard/EcommerceProductList"))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import("../../../pages/dashboard/EcommerceProductCreate"))
);
const EcommerceCheckout = Loadable(
  lazy(() => import("../../../pages/dashboard/EcommerceCheckout"))
);
const EcommerceInvoice = Loadable(
  lazy(() => import("../../../pages/dashboard/EcommerceInvoice"))
);
const Resultoneway = Loadable(
  lazy(() => import("../../../pages/dashboard/resultoneway"))
);
const Resulttwoway = Loadable(
  lazy(() => import("../../../pages/dashboard/resulttwoway"))
);

// const Travellersinfo = Loadable(
//   lazy(() => import("../../../pages/dashboard/TravellersInfo"))
// );
const AgentToCheckRules = Loadable(
  lazy(() =>
    import("../../.././components/_dashboard/adminPages/AdminFareRuleChooseAgent")
  )
);
const BulkData = Loadable(
  lazy(() => import("../../../pages/ScrappingManegment/Bulkdata"))
);
const ScrappyUser = Loadable(
  lazy(() => import("../../../pages/ScrappingManegment/AddScrappyUser"))
);
const ViewScrappyUser = Loadable(
  lazy(() => import("../../../pages/ScrappingManegment/ViewScappyUser"))
);
const GetMeta = Loadable(lazy(() => import("../../../pages/Meta-mange")));
const AppliedRule = Loadable(
  lazy(() => import("../../../pages/FareRule/ExistingFareRule"))
);
const ViewAllData = Loadable(
  lazy(() => import("../../../pages/ScrappingManegment/ViewData"))
);
const AddIternary = Loadable(
  lazy(() => import("../../../pages/ScrappingManegment/AddIternary"))
);
const Approval = Loadable(
  lazy(() => import("../../../pages/PaymentRequests/approval"))
);
const AllRequest = Loadable(
  lazy(() => import("../../../pages/PaymentRequests/AllRequest"))
);
const ChooseAgent = Loadable(
  lazy(() => import("../../../pages/AccountTranscation/ChooseAgent"))
);
const AgentLedger = Loadable(
  lazy(() => import("../../../pages/AccountTranscation/AgentLedger"))
);
// const CreateBooking = Loadable(lazy(() => import("../../../pages/BookingApi")));
const Cancellations = Loadable(lazy(() => import("../../../pages/cancellations")));
const BlogPosts = Loadable(
  lazy(() => import("../../../pages/dashboard/BlogPosts"))
);
const BlogPost = Loadable(lazy(() => import("../../../pages/dashboard/BlogPost")));
//Harsh
const AgentInfoTable = Loadable(
  lazy(() =>
    import("../../../pages/RelationshipManager/AgentSection/AgentInfoTable")
  )
);
const AddAgents = Loadable(
  lazy(() => import("../../../pages/RelationshipManager/AgentSection/AddAgents"))
);
const EditAgentInfo = Loadable(
  lazy(() =>
    import("../../../pages/RelationshipManager/AgentSection/EditAgentInfo")
  )
);
const ViewAgentInfo = Loadable(
  lazy(() =>
    import("../../../pages/RelationshipManager/AgentSection/ViewAgentInfo")
  )
);
const AgentWallet = Loadable(
  lazy(() => import("../../../pages/RelationshipManager/AgentSection/AgentWallet"))
);
const TwoWayTravellersInfo = Loadable(
  lazy(() => import("../../../pages/dashboard/TwoWayTravellersInfo"))
);
const ManagerTable = Loadable(
  lazy(() => import("../../../pages/Manager/ManagerTable"))
);
const AddManager = Loadable(
  lazy(() => import("../../../pages/Manager/AddManager"))
);
const EditManagerInfo = Loadable(
  lazy(() => import("../../../pages/Manager/editManagerInfo"))
);
const ManagerProfilePage = Loadable(
  lazy(() => import("../../../pages/Manager/ManagerProfilePage"))
);
const SubAgentTable = Loadable(
  lazy(() => import("../../../pages/AgentPortal/SubAgentTable"))
);
const AddAgentInfo = Loadable(
  lazy(() => import("../../../pages/AgentPortal/AddSubAgent"))
);
const EditSubAgent = Loadable(
  lazy(() => import("../../../pages/AgentPortal/EditSubAgent"))
);
const SubAgentProfile = Loadable(
  lazy(() => import("../../../pages/AgentPortal/SubAgentProfile"))
);
const SubAgentWallet = Loadable(
  lazy(() => import("../../../pages/AgentPortal/SubAgentWallet"))
);
const OTPVerification = Loadable(
  lazy(() => import("../../../pages/authentication/OTPVerification"))
);
const AddAccountant = Loadable(
  lazy(() => import("../../../pages/AccountsDepartment/AddAccountant"))
);
const AccountsRequest = Loadable(
  lazy(() => import("../../../pages/AccountsDepartment/AccountsRequest"))
);
const AccountantTable = Loadable(
  lazy(() => import("../../../pages/AccountsDepartment/AccountantTable"))
);
const BookingTablePage = Loadable(
  lazy(() => import("../../../pages/Bookings/BookingTablePage"))
);
const BookingTablePageHotels = Loadable(
  lazy(() => import("../../../pages/hotels/BookingTablePageHotels"))
);
const BookingTablePageTransfers = Loadable(
  lazy(() => import("../../../pages/Transfer/BookingTablePageTransfers"))
);
const InhouseAgentTable = Loadable(
  lazy(() => import("../../../pages/InhouseAgents/InhouseAgentTable"))
);
const AddInhouseAgents = Loadable(
  lazy(() => import("../../../pages/InhouseAgents/AddInhouseAgents"))
);
const EditBookingDetails = Loadable(
  lazy(() => import("../../../pages/InhouseAgents/EditBookingDetails"))
);
const AmendmentPage = Loadable(
  lazy(() => import("../../../pages/AgentPortal/AmendmentPage"))
);
const Amendments = Loadable(
  lazy(() => import("../../../pages/InhouseAgents/Amendments"))
);
const AmendmentsHotels = Loadable(
  lazy(() => import("../../../pages/hotels/AmendmentsHotels"))
);
const AmendmentsTransfers = Loadable(
  lazy(() => import("../../../pages/Transfer/AmendmentsTransfers"))
);
//Harsh

const BlogNewPost = Loadable(
  lazy(() => import("../../../pages/dashboard/BlogNewPost"))
);
const UserProfile = Loadable(
  lazy(() => import("../../../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(
  lazy(() => import("../../../pages/dashboard/UserCards"))
);
const UserList = Loadable(lazy(() => import("../../../pages/dashboard/UserList")));
const UserAccount = Loadable(
  lazy(() => import("../../../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../../../pages/dashboard/UserCreate"))
);
const Chat = Loadable(lazy(() => import("../../../pages/dashboard/Chat")));
const Mail = Loadable(lazy(() => import("../../../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../../../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../../../pages/dashboard/Kanban")));
// Main
const FlightBooking = Loadable(lazy(() => import("../../../pages/Flightbooking")));
const HotelBooking = Loadable(
  lazy(() => import("../../../pages/hotels/HotelBookingPage"))
);
const HotelListing = Loadable(
  lazy(() => import("../../../pages/hotels/HotelListing.js"))
);

const TransferMain = Loadable(
  lazy(() => import("../../../pages/Transfer/TransferMain.js"))
);

const PrivacyPolicy = Loadable(
  lazy(() => import("../../../pages/AbboutUs/privacyPolicy"))
);
const TermsAndConditons = Loadable(
  lazy(() => import("../../../pages/AbboutUs/Terms&Conditions"))
);

const Topup = Loadable(
  lazy(() => import("../../../components/_dashboard/Transaction/TopUp"))
);
const FareRule = Loadable(
  lazy(() => import("../../../components/_dashboard/adminPages/FareRule"))
);
const ApplyRule = Loadable(
  lazy(() => import("../../../pages/FareRule/ApplyRule"))
);
const Alltransaction = Loadable(
  lazy(() => import("../../../pages/PaymentRequests/AgentTransactions"))
);
const Transaction = Loadable(
  lazy(() => import("../../../pages/AccountTranscation/Transaction"))
);
const BookingsHistory = Loadable(
  lazy(() => import("../../../pages/Bookings/userBookings"))
);
// Components

export const SubAgentRoutes = () => {
  const parsedData = decryptData("_b60-g6fr(+");

  return {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),

    children: [
      {
        path: "",
        element: (
          <Navigate
            to={
              parsedData === null
                ? "/dashboard"
                : parsedData?.role === "admin"
                ? "/dashboard/manager-info"
                : parsedData?.role === "manager"
                ? "/dashboard/add-info"
                : parsedData?.role === "agent"
                ? "/dashboard/sub-agent-info"
                : parsedData?.role === "sub-agent"
                ? "/dashboard/bookingsHistory"
                : "/dashboard"
            }
            replace
          />
        ),
      },
      // new path
      // { path: "hotelResult", element: <>Holter</> },

      { path: "app", element: <GeneralApp /> },

      { path: "ecommerce", element: <GeneralEcommerce /> },
      { path: "banking", element: <GeneralBanking /> },
      { path: "booking", element: <GeneralBooking /> },
      {
        path: "analytics",
        element: <GeneralAnalytics />,
      },
      { path: "flight_booking", element: <FlightBooking /> },
      { path: "hotel_booking", element: <HotelBooking /> },
      { path: "hotelResult", element: <HotelListing /> },
      { path: "transfer_booking", element: <TransferMain /> },
      { path: "privacyPolicy", element: <PrivacyPolicy /> },
      { path: "termsAndConditions", element: <TermsAndConditons /> },
      { path: "resultoneway", element: <Resultoneway /> },
      { path: "resulttwoway", element: <Resulttwoway /> },
      { path: "farerule", element: <FareRule /> },
      { path: "applyrule", element: <ApplyRule /> },
      { path: "trans", element: <Transaction /> },
      { path: "topup", element: <Topup /> },
      { path: "AppliedRule", element: <AppliedRule /> },
      { path: "bookingsHistory", element: <BookingsHistory /> },
      { path: "approval", element: <Approval /> },
      { path: "alltransaction", element: <Alltransaction /> },
      { path: "chooseAgent", element: <ChooseAgent /> },
      { path: "agentLedger", element: <AgentLedger /> },
      { path: "cancellations", element: <Cancellations /> },
      { path: "checkAgentRules", element: <AgentToCheckRules /> },
      { path: "scrap-all-data", element: <ViewAllData /> },
      { path: "add-Iternary", element: <AddIternary /> },
      { path: "add-bulk-data", element: <BulkData /> },
      { path: "add-scrappy-user", element: <ScrappyUser /> },
      { path: "view-scrappy-user", element: <ViewScrappyUser /> },
      { path: "get-meta", element: <GetMeta /> },
      { path: "add-info", element: <AgentInfoTable /> },
      { path: "add-agent-info", element: <AddAgents /> },
      { path: "edit-agent-info", element: <EditAgentInfo /> },
      { path: "agent-info", element: <ViewAgentInfo /> },
      { path: "agent-wallet", element: <AgentWallet /> },
      { path: "manager-info", element: <ManagerTable /> },
      { path: "all-requests", element: <AllRequest /> },
      { path: "edit-manager-info", element: <EditManagerInfo /> },
      { path: "sub-agent-info", element: <SubAgentTable /> },
      { path: "add-sub-agent-info", element: <AddAgentInfo /> },
      { path: "edit-sub-agent-info", element: <EditSubAgent /> },
      { path: "sub-agent-profile", element: <SubAgentProfile /> },
      { path: "sub-agent-wallet", element: <SubAgentWallet /> },
      { path: "add-accountant", element: <AddAccountant /> },
      { path: "accounts-all-requests", element: <AccountsRequest /> },
      { path: "accounts-department-info", element: <AccountantTable /> },
      { path: "all-booking-details", element: <BookingTablePage /> },
      {
        path: "all-booking-details-hotels",
        element: <BookingTablePageHotels />,
      },
      {
        path: "all-booking-details-transfers",
        element: <BookingTablePageTransfers />,
      },
      { path: "show-inhouse-agents", element: <InhouseAgentTable /> },
      { path: "add-inhouseAgents", element: <AddInhouseAgents /> },
      { path: "get-all-amendments", element: <Amendments /> },
      {
        path: "get-all-amendments-hotels",
        element: <AmendmentsHotels />,
      },
      {
        path: "get-all-amendments-transfers",
        element: <AmendmentsTransfers />,
      },
      { path: "enquiry-table", element: <EnquiryTable /> },
      { path: "demo-table", element: <DemoTable /> },

      {
        path: "add-manager-info",
        element: <AddManager />,
      },
      {
        path: "manager-profile",
        element: <ManagerProfilePage />,
      },
      // { path: "edit-booking-details", element: <EditBookingDetails /> },
      {
        path: "e-commerce",
        children: [
          {
            path: "",
            element: <Navigate to="/dashboard/e-commerce/shop" replace />,
          },
          { path: "shop", element: <EcommerceShop /> },
          { path: "product/:name", element: <EcommerceProductDetails /> },
          { path: "list", element: <EcommerceProductList /> },
          { path: "product/new", element: <EcommerceProductCreate /> },
          {
            path: "product/:name/edit",
            element: <EcommerceProductCreate />,
          },
          { path: "checkout", element: <EcommerceCheckout /> },
          { path: "invoice", element: <EcommerceInvoice /> },
        ],
      },
      {
        path: "user",
        children: [
          {
            path: "",
            element: <Navigate to="/dashboard/user/profile" replace />,
          },
          { path: "profile", element: <UserProfile /> },
          { path: "cards", element: <UserCards /> },
          { path: "list", element: <UserList /> },
          { path: "new", element: <UserCreate /> },
          { path: "product/:name/edit", element: <UserCreate /> },
          { path: "account", element: <UserAccount /> },
        ],
      },
      {
        path: "blog",
        children: [
          {
            path: "",
            element: <Navigate to="/dashboard/blog/posts" replace />,
          },
          { path: "posts", element: <BlogPosts /> },
          { path: "post/:title", element: <BlogPost /> },
          { path: "new-post", element: <BlogNewPost /> },
        ],
      },
      {
        path: "mail",
        children: [
          {
            path: "",
            element: <Navigate to="/dashboard/mail/all" replace />,
          },
          { path: "label/:customLabel", element: <Mail /> },
          { path: "label/:customLabel/:mailId", element: <Mail /> },
          { path: ":systemLabel", element: <Mail /> },
          { path: ":systemLabel/:mailId", element: <Mail /> },
        ],
      },
      {
        path: "chat",
        children: [
          { path: "", element: <Chat /> },
          { path: "new", element: <Chat /> },
          { path: ":conversationKey", element: <Chat /> },
        ],
      },
      { path: "calendar", element: <Calendar /> },
      { path: "kanban", element: <Kanban /> },
    ],
  };
};
