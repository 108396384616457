import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { LoadingButton } from "@mui/lab";
import { addMarkupApi } from "src/Api/markupApi/markupApi";
import { decryptData } from "src/utils/decryptcookie";
import { useSnackbar } from "notistack5";
import { getUserById } from "src/Api/AgentApis/GetAgentApi";

const AgentMarkup = ({ handleClose }) => {
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userId = decryptData("_b60-g6fr(+");
  const handleAddMarkup = async () => {
    const apiData = {
      userid: userId?.userid,
      amount: amount,
      token: userId?.token,
      setIsLoading: setIsLoading,
    };
    const response = await addMarkupApi(apiData);
    console.log("response", response);
    if (response?.data?.status === true) {
      setAmount(response?.data?.markup);
      handleClose();
      enqueueSnackbar(response?.data?.message, { variant: "success" });
    } else {
      enqueueSnackbar(response?.data?.message, { variant: "error" });
    }
  };
  const handleAmount = (e) => {
    setAmount(e.target.value);
  };
  const userMarkupInfoApi = async () => {
    const { data } = await getUserById(userId?.userid);
    console.log(data);
    if (data?.baseResponse?.status === 1) {
      setAmount(data?.response?.markup);
    } else {
      setError(true);
    }
  };
  useEffect(() => {
    userMarkupInfoApi();
  }, []);
  if (isError) {
    return <h2>Unable to show Markup</h2>;
  }
  return (
    <div>
      <Box>
        <div className="container mb-[10px]">
          <HeaderBreadcrumbs heading="Add Markup" />
        </div>
      </Box>
      <section>
        <div className="bg-white cardBorder d-flex flex-column p-[30px] gap-3 max-w-[300px]">
          <TextField
            autoFocus
            type="number"
            placeholder="Amount"
            value={amount}
            required={true}
            onChange={(e) => handleAmount(e)}
            // onChange={(e) => handleAmount(e)}
          />
          <LoadingButton
            size="large"
            type="submit"
            onClick={() => handleAddMarkup()}
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Add Markup</span>
          </LoadingButton>
        </div>
      </section>
    </div>
  );
};

export default AgentMarkup;
