import { lazy, Suspense } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import LoadingScreen from "../../../components/LoadingScreen.js";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// component
const TransferListingPage = Loadable(
  lazy(() =>
    import("../../../pages/Transfer/TransferBooking/TransferListingPage.js")
  )
);
const TransferCheckoutPage = Loadable(
  lazy(() =>
    import("../../../pages/Transfer/TransferBooking/TransferCheckoutPage.js")
  )
);
const TransferBookingPage = Loadable(
  lazy(() =>
    import("../../../pages/Transfer/TransferBooking/TransferBookingPage.js")
  )
);

// i just call in path
export const TransferRoutes = () => ({
  path: "transfer",
  children: [
    {
      path: "",
      element: <Navigate to="/transfer" replace />,
    },
    { path: "transfer_result", element: <TransferListingPage /> },
    { path: "transfer_checkout", element: <TransferCheckoutPage /> },
    // { path: "transfer_checkout", element: <TransferBookingPage /> },
    // { path: "twotravellerinfo", element: <TwoWayTravellersInfo /> },
    // { path: "createBooking", element: <CreateBooking /> },
  ],
});
