import React from "react";
import AgentMarkup from "../_dashboard/AgentMarkup/AgentMarkup";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

const AgentMarkupDialog = ({ setOpenBookingModal, openBookingModal }) => {
  const handleClose = () => {
    setOpenBookingModal(false);
  };
  return (
    <div>
      <Dialog open={openBookingModal} onClose={handleClose}>
        <DialogTitle>Agent Markup</DialogTitle>
        <DialogContent>
          <AgentMarkup handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AgentMarkupDialog;
