// routes
import { PATH_DASHBOARD, PATH_PAGE } from "../../routes/paths";
// components
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  home: getIcon("ic_home"),
  rule: getIcon("ic_rules"),
  topUp: getIcon("ic_topup"),
  transaction: getIcon("ic_transaction"),
  airport: getIcon("ic_airport"),
  home: getIcon("ic_home"),
  wallet: getIcon("ic_wallet"),
  bookingConfirmed: getIcon("ic_booking-confirmed"),
  allData: getIcon("ic_allData"),
  add: getIcon("ic_add"),
};

const sidebarConfig = [
  // GENERAL
  {
    subheader: "Users",
    items: [
      {
        title: "Relationship Managers",
        path: PATH_DASHBOARD.general.manager_info,
        icon: ICONS.user,
      },
      {
        title: " Travel Agents",
        path: PATH_DASHBOARD.general.add_info,
        icon: ICONS.user,
      },
      {
        title: "Accounts Department",
        path: PATH_DASHBOARD.general.accounts_department_info,
        icon: ICONS.user,
      },
      {
        title: "Inhouse Agents",
        path: PATH_DASHBOARD.general.show_inhouse_agents,
        icon: ICONS.user,
      },
    ],
  },

  // ----------------------------------------------------------------------
  {
    subheader: "Bookings",
    items: [
      {
        title: "Flights",
        icon: ICONS.airport,
        children: [
          {
            title: "Book Flights",
            path: PATH_DASHBOARD.general.flightBooking,
          },
          {
            title: "Bookings Flights",
            path: PATH_DASHBOARD.general.all_booking_details,
          },
          {
            title: "Amendments Flights",
            path: PATH_DASHBOARD.general.get_all_amendments,
            icon: ICONS.airport,
          },
        ],
      },
      {
        title: "Hotels",
        icon: ICONS.airport,
        children: [
          {
            title: "Book Hotels",
            path: PATH_DASHBOARD.general.hotelBooking,
          },
          {
            title: "Bookings Hotels",
            path: PATH_DASHBOARD.general.all_booking_details_hotels,
          },
          // {
          //   title: "Amendments Hotels",
          //   path: PATH_DASHBOARD.general.get_all_amendments_hotels,
          // },
        ],
      },
      // add transfer
      // {
      //   title: "Transfers",
      //   icon: ICONS.airport,
      //   children: [
      //     {
      //       title: "Book Transfers",
      //       path: PATH_DASHBOARD.general.transfersBooking,
      //     },
      //     {
      //       title: "Bookings Transfers",
      //       path: PATH_DASHBOARD.general.all_booking_details_transfers,
      //     },
      //     {
      //       title: "Amendments Transfers",
      //       path: PATH_DASHBOARD.general.get_all_amendments_transfers,
      //     },
      //   ],
      // },
      // {
      //   title: "Book Flights",
      //   path: PATH_DASHBOARD.general.flightBooking,
      //   icon: ICONS.airport,
      // },
      // {
      //   title: "Bookings",
      //   path: PATH_DASHBOARD.general.all_booking_details,
      //   icon: ICONS.airport,
      // },
      // {
      //   title: "Amendments",
      //   path: PATH_DASHBOARD.general.get_all_amendments,
      //   icon: ICONS.airport,
      // },
      // {
      //   title: "Cancellations",
      //   path: PATH_PAGE.comingSoon,
      //   icon: ICONS.banking,
      // },
      // {
      //   title: "banking",
      //   path: PATH_DASHBOARD.general.banking,
      //   icon: ICONS.banking,
      // },
      // {
      //   title: "booking",
      //   path: PATH_DASHBOARD.general.booking,
      //   icon: ICONS.booking,
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  // subheader: "Management",
  // items: [
  // MANAGEMENT : USER
  // {
  //   title: " Tarvel Agents",
  //   //  path: PATH_DASHBOARD.mail.root,
  //   path: PATH_DASHBOARD.general.add_info,
  //   icon: ICONS.rule,
  // },
  // {
  //   title: "Orders",
  //   path: PATH_DASHBOARD.blog.root,
  //   path: PATH_PAGE.comingSoon,
  //   icon: ICONS.booking,
  // },
  // {
  //   title: "Analytics",
  //   path: PATH_DASHBOARD.general.analytics,
  //   path: PATH_PAGE.comingSoon,
  //   icon: ICONS.analytics,
  // ],
  // },

  // MANAGEMENT : E-COMMERCE
  // {
  //   title: "Wallet",
  //   path: PATH_DASHBOARD.eCommerce.root,
  //   icon: ICONS.cart,
  //   children: [
  //     { title: "shop", path: PATH_DASHBOARD.eCommerce.shop },
  //     { title: "product", path: PATH_DASHBOARD.eCommerce.productById },
  //     { title: "list", path: PATH_DASHBOARD.eCommerce.list },
  //     { title: "create", path: PATH_DASHBOARD.eCommerce.newProduct },
  //     { title: "edit", path: PATH_DASHBOARD.eCommerce.editById },
  //     { title: "checkout", path: PATH_DASHBOARD.eCommerce.checkout },
  //     { title: "invoice", path: PATH_DASHBOARD.eCommerce.invoice },
  //   ],
  // },

  // MANAGEMENT : BLOG
  // {
  //   title: "Analytics",
  //   path: PATH_DASHBOARD.blog.root,
  //   icon: ICONS.blog,
  //   children: [
  //     { title: "posts", path: PATH_DASHBOARD.blog.posts },
  //     { title: "post", path: PATH_DASHBOARD.blog.postById },
  //     { title: "new post", path: PATH_DASHBOARD.blog.newPost },
  //   ],
  // },
  // ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: "Wallet",
  //   items: [
  //     {
  //       title: "Balance",
  //       //  path: PATH_DASHBOARD.mail.root,
  //       path: PATH_PAGE.comingSoon,
  //       icon: ICONS.wallet,
  //     },
  //     // {
  //     //   title: "Top Up",
  //     //   path: PATH_DASHBOARD.TopUpMain.topup,
  //     //   icon: ICONS.topUp,
  //     // },
  //     {
  //       title: "Transactions",
  //       path: PATH_DASHBOARD.general.chooseAgent,
  //       icon: ICONS.transaction,
  //     },
  //   ],
  // },
  // {
  //   subheader: "Fare Rule",
  //   items: [
  //     {
  //       title: "All Fare Rule",
  //       path: PATH_DASHBOARD.farerule.checkAgentRules,
  //       icon: ICONS.rule,
  //     },
  //     {
  //       title: "Add fare rule",
  //       path: PATH_DASHBOARD.farerule.farerule,
  //       icon: ICONS.rule,
  //     },
  //   ],
  // },

  // {
  //   subheader: "Scrapping Data Management",
  //   items: [
  //     {
  //       title: "View all data",
  //       path: PATH_DASHBOARD.general.scrap_all_data,
  //       icon: ICONS.allData,
  //     },
  //     {
  //       title: "Add data",
  //       path: PATH_DASHBOARD.general.add_Iternary,
  //       icon: ICONS.add,
  //     },
  //     {
  //       title: "Add bulk data",
  //       path: PATH_DASHBOARD.general.add_bulk_data,
  //       icon: ICONS.add,
  //     },
  //   ],
  // },
  {
    subheader: "Transactions",
    items: [
      {
        title: "Transactions",
        path: PATH_DASHBOARD.general.accounts_all_requests,
        icon: ICONS.rule,
      },
      {
        title: "Invoice",
        // path: PATH_DASHBOARD?.general.invoice,
        icon: ICONS.rule,
        children: [
          {
            title: "Flight Invoice",
            path: PATH_DASHBOARD.general.invoice_flight,
          },
          // {
          //   title: "Hotel Invoice",
          //   path: PATH_DASHBOARD.general.invoice_hotel,
          // }
        ],
      },
    ],
  },
  {
    subheader: "Enquiries",
    items: [
      {
        title: "Partner Registration",
        path: PATH_DASHBOARD.general.enquiry_table,
        icon: ICONS.rule,
      },
      {
        title: "Demo Request",
        path: PATH_DASHBOARD.general.demo_table,
        icon: ICONS.rule,
      },
    ],
  },
];

export default sidebarConfig;
