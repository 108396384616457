// BASE URL Main
// export const hostname = "http://10.20.1.73:6500/";
// export const hostname = "http://10.20.1.93:7000/"; // tarun backend
export const hostname = "https://partnersapi.travomint.co/"; // Live Url

// export const payuUrl = "http://10.20.1.34:3001/";
export const payuUrl = "http://13.127.172.138:3001/";
// export const payuUrl = "https://routes.traveloes.com/"; // Live Url
// export const hostname = "http://13.127.172.138:7000/";
export const tempHost = "http://10.20.1.38:1001/";
// export const hostname2 = "http://10.20.1.100:1001/";
// export const hostname = "http://www.traveloes.com:6500/";
// export const flightHost = "http://35.154.18.223:3001/";
// export const flightHost = "http://13.127.172.138:3001/";

// export const flightHost = "http://13.127.172.138:3001/"; // Staging flight Url
export const flightHost = "https://flight-staging-api.travomint.com/"; // staging
// export const flightHost = "http://10.20.1.75:3001/";
export const scrapping_Host = "http://44.224.252.119:2500/";
// export const scrapping_Host = "http://10.20.1.73:2500/";
export const flightHostLive = "http://44.224.252.119:3001/";

export const mediaPath = `${hostname}images/`;
export const secreteKey = "TrVlOsEsEcReTeKeY";

// Hotel
// export const HOTEL_BASE_URL = "https://hotelapi.travomint.com"; // travomint
export const HOTEL_BASE_URL = " https://hotelstaging-api.travomint.com"; // staging
// export const HOTEL_BASE_URL = "10.20.1.73:2800"; // development

// Transfer
export const TRANSFER_BASE_URL = "https://transferapi.travomint.com"; // travomint
// export const TRANSFER_BASE_URL = "https://hotelapi.travomint.com"; // staging
// export const TRANSFER_BASE_URL = "10.20.1.73:2800"; // development