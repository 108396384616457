import { hostname } from "src/HostName";
import axios from "axios";
export const getAgentApi = async (managerId) => {
  try {
    const data = await axios.get(`${hostname}get_agent/53`);
    return data;
  } catch (error) {
    console.log("error", error.message);
    return error.message;
  }
};

export const getAgentById = async (agentId) => {
  try {
    const options = {
      method: "GET",
      url: `${hostname}agent_byId/${agentId}`,
    };
    const data = await axios.request(options);
    return data;
  } catch (error) {
    return error.message;
  }
};

export const getUserById = async (userId) => {
  try {
    const options = {
      method: "GET",
      url: `${hostname}get_user_byid/${userId}`,
    };
    const data = await axios.request(options);
    return data;
  } catch (error) {
    return error.message;
  }
};
