import { PATH_DASHBOARD, PATH_PAGE } from "../../routes/paths";
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";
import useAuth from "src/hooks/useAuth";

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);
const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  home: getIcon("ic_home"),
  rule: getIcon("ic_rules"),
  topUp: getIcon("ic_topup"),
  transaction: getIcon("ic_transaction"),
  airport: getIcon("ic_airport"),
  home: getIcon("ic_home"),
  wallet: getIcon("ic_wallet"),
  bookingConfirmed: getIcon("ic_booking-confirmed"),
};
const sidebarConfig = [
  // GENERAL
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Sub Agents",
        path: PATH_DASHBOARD.general.sub_agent_info,
        icon: ICONS.rule,
      },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
    ],
  },

  // ----------------------------------------------------------------------
  // {
  //   subheader: "Flight",
  //   items: [
  //     {
  //       title: "Flight Booking",
  //       path: PATH_DASHBOARD.general.flightBooking,
  //       icon: ICONS.airport,
  //     },
  //     {
  //       title: "Booking History",
  //       path: PATH_DASHBOARD.history.book,
  //       icon: ICONS.bookingConfirmed,
  //     },
  //     {
  //       title: "Amendments",
  //       path: PATH_DASHBOARD.general.get_all_amendments,
  //       icon: ICONS.airport,
  //     },
  //     {
  //       title : "Transfer PNR",
  //       path : PATH_DASHBOARD.general.transfer_pnr,
  //       icon : ICONS.airport
  //     }
  //   ],
  // },
  {
    subheader: "Bookings",
    items: [
      {
        title: "Flights",
        icon: ICONS.airport,
        children: [
          {
            title: "Book Flights",
            path: PATH_DASHBOARD.general.flightBooking,
          },
          {
            title: "Bookings Flights",
            path: PATH_DASHBOARD.history.book,
          },
          // {
          //   title: "Amendments Flights",
          //   path: PATH_DASHBOARD.general.get_all_amendments,
          //   icon: ICONS.airport,
          // },
          // {
          //   title: "Transfer PNR",
          //   path: PATH_DASHBOARD.general.transfer_pnr,
          //   icon: ICONS.airport,
          // },
        ],
      },
      {
        title: "Hotels",
        icon: ICONS.airport,
        children: [
          {
            title: "Book Hotels",
            path: PATH_DASHBOARD.general.hotelBooking,
          },
          {
            title: "Bookings Hotels",
            path: PATH_DASHBOARD.general.all_booking_details_hotels,
          },
          // {
          //   title: "Amendments Hotels",
          //   path: PATH_DASHBOARD.general.get_all_amendments_hotels,
          // },
        ],
      },
    ],
  },
  {
    subheader: "Transaction",
    items: [
      {
        title: "Request Wallet Amount",
        path: PATH_DASHBOARD.TopUpMain.topup,
        icon: ICONS.topUp,
      },
      {
        title: "Transactions",
        path: PATH_DASHBOARD.general.accounts_all_requests,
        icon: ICONS.rule,
      },
      {
        title: "Invoice",
        // path: PATH_DASHBOARD?.general.invoice,
        icon: ICONS.rule,
        children: [
          {
            title: "Flight Invoice",
            path: PATH_DASHBOARD.general.invoice_flight,
          },
          // {
          //   title: "Hotel Invoice",
          //   path: PATH_DASHBOARD.general.invoice_hotel,
          // },
        ],
      },
      //   {
      //     title: "Cancellations",
      //     path: PATH_DASHBOARD.general.cancellations,
      //     icon: ICONS.banking,
      //   },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Wallet',
  //   items: [
  //     {
  //       title: 'Balance',
  //       //  path: PATH_DASHBOARD.mail.root,
  //       path: PATH_PAGE.comingSoon,
  //       icon: ICONS.wallet,
  //     },
  //     {
  //       title: 'Top Up',
  //       path: PATH_DASHBOARD.TopUpMain.topup,
  //       icon: ICONS.topUp,
  //     },
  //     {
  //       title: 'Your Transaction',
  //       path: PATH_DASHBOARD.AccTrans.agentLedger,
  //       icon: ICONS.transaction,
  //     },
  //   ],
  // },

  // {
  //   subheader: "",
  //   items: [
  //     // {
  //     //   title: "Sub Agents Info",
  //     //   //  path: PATH_DASHBOARD.mail.root,
  //     //   path: PATH_DASHBOARD.farerule.checkAgentRules,
  //     //   icon: ICONS.rule,
  //     // },
  //     // {
  //     //   title: "Sub Agents Queries",
  //     //   path: PATH_DASHBOARD.aboutus.terms,
  //     //   icon: ICONS.rule,
  //     // },
  //   ],
  // },
];
export default sidebarConfig;
