import axios from "axios";
import { hostname } from "src/HostName";
export const transferPnr = async (
  userInformation,
  values,
  setIsLoading,
  setIsError
) => {
  setIsLoading(true);
  const { token, userid } = userInformation;
  try {
    const options = {
      method: "POST",
      url: `${hostname}pnr/transfer`,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      data: { userid: userid, Pnr: values.pnr, gds: values.gdsType },
    };
    const { data } = await axios.request(options);
    return data;
  } catch (error) {
    setIsError(true);
  } finally {
    setIsLoading(false);
  }
};

export const getTransferdPNR = async (
  userInformation,
  setPNRLoading,
  setPNRError
) => {
  setPNRLoading(true);
  const { token, userid } = userInformation;
  try {
    const options = {
      method: "GET",
      url: `${hostname}get/pnr/transfer/history`,
      params: { userid: userid },
      headers: {
        "x-access-token": token,
      },
    };
    const { data } = await axios.request(options);
    return data;
  } catch (error) {
    setPNRError(true);
  } finally {
    setPNRLoading(false);
  }
};

export const updateTransferPNRStatus = () => {
  try {
    const options = {
      method: "POST",
      url: `/puch/pnr`,
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "insomnia/8.6.1",
        "x-access-token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InNhY2hpbiIsInBhc3N3b3JkIjoic2FjaGluQDEyMyIsInJvbGUiOiJhZ2VudCIsImlhdCI6MTcxMDgyNTA4OCwiZXhwIjoxNzEzNDE3MDg4fQ.FDHfVBNt9KbHyrnmmVX9BTgZHmdkfJC4c-uxVsFES2I",
      },
      data: {
        userid: 15,
        Pnr: "csivbe2378431",
        gds: "",
        amount: 100,
        text: "",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
  } finally {
  }
};
