import { merge, set } from "lodash";
import { Icon } from "@iconify/react";
import ReactApexChart from "react-apexcharts";
import personFill from "@iconify/icons-eva/person-fill";
// material
import { useTheme, styled } from "@material-ui/core/styles";
import { Card, Typography, Box } from "@material-ui/core";
// utils
import { fNumber } from "../../../utils/formatNumber";
import { BaseOptionChart } from "../../charts";
import { useSelector } from "../../../redux/store";
import useAuth from "src/hooks/useAuth";
import { useEffect, useState } from "react";
import { getUserById } from "src/Api/AgentApis/GetAgentApi";
import { decryptData } from "src/utils/decryptcookie";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  boxShadow: "none",
  fontSize: "13px",
  marginRight: "8px",
}));

const IconStyle = styled(Icon)(({ theme }) => ({
  width: 120,
  height: 120,
  opacity: 0.12,
  position: "absolute",
  right: theme.spacing(-3),
  color: theme.palette.common.white,
}));

// ----------------------------------------------------------------------

const TOTAL = 1038566;
const CHART_DATA = [44];

export default function AppWidgets1(props) {
  // const { value } = useSelector((state) => state.TopUp);
  const LoginData = localStorage.getItem("LoginData");
  const [userDetails, setUserDetails] = useState({});
  const updateBalance = useSelector((state) => state.updateWallet.updateWallet);
  const user = decryptData("_b60-g6fr(+");
  const theme = useTheme();
  const navigate = useNavigate();

  const chartOptions = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: "78%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            color: theme.palette.common.white,
            fontSize: theme.typography.subtitle2.fontSize,
          },
        },
      },
    },
  });
  
  const userDetailApi = async () => {
    const data = await getUserById(user.userid);
    if (data?.data?.baseResponse?.status === 1) {
      setUserDetails(data.data.response);
    } else {
      setUserDetails({});
    }
  };

  useEffect(() => {
    userDetailApi();
  }, [updateBalance]);

  return (
    <RootStyle className="px-2">
      {Object.keys(userDetails).length === 0 ? (
        "Loading....."
      ) : (
        <>
          <Button
            variant="contained"
            className="!mr-3"
            onClick={() => {
              user.role === "agent"
                ? navigate("/dashboard/sub-agent-info")
                : user.role === "manager"
                ? navigate("/dashboard/add-info")
                : user.role === "admin"
                ? navigate("/dashboard/manager-info")
                : user.role === "sub-agent"
                ? navigate("/dashboard/bookingsHistory")
                : navigate("/dashboard/app");
            }}
          >
            Go to dashboard
          </Button>
          <div className="wallet-bal">
            <b>
              <img
                src="/static/brand/rupee-coin.png"
                class="rupee-ico"
                alt="rupee"
              />
              Balance:
            </b>{" "}
            {user === null
              ? "0"
              : user.role === "scrappy"
              ? "1,00,000"
              : fNumber(userDetails.balance)}
          </div>
          <IconStyle icon={personFill} />
        </>
      )}
    </RootStyle>
  );
}
