import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { alpha, styled } from "@material-ui/core/styles";
import {
  Box,
  Link,
  Stack,
  Button,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
} from "@material-ui/core";
// hooks
import useAuth from "../../hooks/useAuth";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// routes
import { PATH_DASHBOARD, PATH_DOCS } from "../../routes/paths";
// components
import Logo from "../../components/Logo";
import MyAvatar from "../../components/MyAvatar";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import { fNumber } from "../../utils/formatNumber";
import sidebarConfig from "./SidebarConfig";
import userSidebarConfig from "./userSidebarConfig";
import RmSideBar from "./RmSideBarConfig";
import scrappyConfig from "./scrappyConfig";
import accountsSideBar from "./AccountsSidebar";
import scrappyUserSidebarConfig from "./scrappy-userSidebarConfig";
import AgentSidebar from "./AgentSideBar";
import inhouseAgentSidebar from "./InhouseAgentSidebar";
import { DocIllustration } from "../../assets";
import LogoOnlyLayout from "../LogoOnlyLayout";
import { useSelector } from "../../redux/store";
import Traveloes from "src/components/TraveloesLogo";
import { decryptData } from "src/utils/decryptcookie";
import SubAgentSidebar from "./SubAgentSidebar";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 100;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool,
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  // const { user } = useAuth();
  const LoginData = localStorage.getItem("LoginData");
  const user = JSON.parse(LoginData);

  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: "flex",
          cursor: "pointer",
          borderRadius: "50%",
          alignItems: "center",
          color: "text.primary",
          justifyContent: "center",
          border: "solid 1px currentColor",
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor: "currentColor",
            transition: (theme) => theme.transitions.create("all"),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const LoginData = localStorage.getItem("LoginData");
  const userDataFromCookie = decryptData("_b60-g6fr(+"); // userData decrypted here from cookies
  const user = JSON.parse(LoginData);
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            {isCollapse ? (
              <Traveloes path="/static/brand/online.png" />
            ) : (
              <Traveloes path="/static/brand/Travomint.png" />
            )}
          </Box>
{/* 
          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse
                onToggleCollapse={onToggleCollapse}
                collapseClick={collapseClick}
              />
            )}
          </MHidden> */}
        </Stack>

        {isCollapse ? (
          <MyAvatar sx={{ mx: "auto", mb: 2 }} />
        ) : (
          <AccountStyle>
            <MyAvatar />
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "text.primary",
                  letterSpacing: "0.5px",
                  fontWeight: 700,
                  fontSize: "17px",
                }}
              >
                {userDataFromCookie?.username}{" "}
              </Typography>
              <Typography
                sx={{
                  color: "text.primary",
                  letterSpacing: "0.5px",
                  fontWeight: 700,
                  fontSize: "11px",
                }}
              >
                {`(${
                  userDataFromCookie?.role === "admin"
                    ? "Admin"
                    : userDataFromCookie?.role === "agent"
                    ? "Travel Agent"
                    : userDataFromCookie?.role === "sub-agent"
                    ? "Sub Tarvel Agent"
                    : userDataFromCookie?.role === "manager"
                    ? "Relationship Manager"
                    : userDataFromCookie?.role === "accounts"
                    ? "Accountant"
                    : userDataFromCookie?.role === "inhouseAgents"
                    ? "Inhouse Agent"
                    : ""
                })`}
              </Typography>
            </Box>
          </AccountStyle>
        )}
      </Stack>

      {(userDataFromCookie === null ? (
        <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
      ) : (
        userDataFromCookie.role
      )) === "scrappy_admin" ? (
        <NavSection navConfig={scrappyConfig} isShow={!isCollapse} />
      ) : userDataFromCookie.role === "scrappy_user" ? (
        <NavSection navConfig={scrappyUserSidebarConfig} isShow={!isCollapse} />
      ) : (
        <>
          {userDataFromCookie.role === "admin" ? (
            <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
          ) : userDataFromCookie.role === "manager" ? (
            <NavSection navConfig={RmSideBar} isShow={!isCollapse} />
          ) : userDataFromCookie.role === "agent" ? (
            <NavSection navConfig={AgentSidebar} isShow={!isCollapse} />
          ) : userDataFromCookie.role === "sub-agent" ? (
            <NavSection navConfig={SubAgentSidebar} isShow={!isCollapse} />
          ) : userDataFromCookie.role === "accounts" ? (
            <NavSection navConfig={accountsSideBar} isShow={!isCollapse} />
          ) : userDataFromCookie.role === "inhouseAgents" ? (
            <NavSection navConfig={inhouseAgentSidebar} isShow={!isCollapse} />
          ) : (
            ""
          )}
        </>
      )}

      <Stack
        spacing={3}
        alignItems="center"
        sx={{ px: 5, pb: 5, mt: 1, width: 1, textAlign: "center" }}
      ></Stack>

      {/* {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: "center" }}
        >
          <DocIllustration sx={{ width: 1 }} />

          <div>
            <Typography gutterBottom variant="subtitle1">
              Hi, {user?.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Need help?
              <br /> Please connect with support
            </Typography>
          </div>
          <Button
            // href={PATH_DOCS}
            style={{ color: "#fff" }}
            target="_blank"
            variant="contained"
          >
            Call Us
          </Button>
        </Stack>
      )} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {/* <Logo /> */}
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) =>
                  alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
