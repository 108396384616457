import {
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getTransferdPNR } from "src/Api/transferPnrAPI/transferPnrApi";
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
import { decryptData } from "src/utils/decryptcookie";
import UpdatePNRRequest from "./UpdatePNRRequest.js";
const InhouseAgentsPNR = () => {
  const [PNRInfo, setPNRInfo] = useState({});
  const [PNRLoading, setPNRLoading] = useState(true);
  const [PNRError, setPNRError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [open, setOpen] = useState();
  const [openUpdatePnr, setOpenUpdatePnr] = useState(false);

  const columnName = [
    { id: 1, name: "PNR", width: 120 },
    { id: 2, name: "Created At", width: 120 },
    { id: 3, name: "GDS", width: 120 },
    { id: 4, name: "Status", width: 120 },
    { id: 5, name: "Updated At", width: 120 },
    {
      id: 6,
      name: "Action",
      width: 150,
    },
  ];
  const userId = decryptData("_b60-g6fr(+");
  const getPNRInfo = async () => {
    const data = await getTransferdPNR(userId, setPNRLoading, setPNRError);
    setPNRInfo(data);
  };
  const handleClickDownload = (row) => {
    setOpen(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClickOpen = () => {
    setOpenUpdatePnr(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getPNRInfo();
  }, []);
  if (PNRLoading) {
    return <h5>Loading...</h5>;
  }
  if (PNRError === true || PNRInfo?.status === false) {
    return <h5>Unable To get PNR List</h5>;
  } else {
    return (
      <div>
        <Card className="">
          <CardHeader title="Your Booking" sx={{ mb: 3 }} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 1000 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columnName.map((items) => (
                      <TableCell sx={{ minWidth: items.width }}>
                        {items.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {PNRInfo?.data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          onClick={() => handleClickDownload(row)}
                          style={{ cursor: "pointer" }}
                        >
                          <Button
                            variant="outlined"
                            size="medium"
                            style={{ padding: "7px 14px", fontWeight: 600 }}
                          >
                            {row.Pnr}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Label>
                            {moment(row?.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </Label>
                        </TableCell>

                        <TableCell>
                          <Label>{row.gds}</Label>
                        </TableCell>

                        <TableCell>
                          <Label
                            sx={{
                              backgroundColor:
                                row.bookingStatus === "Confirm" ||
                                row.bookingStatus === "CONFIRMED"
                                  ? "#90EE90"
                                  : "#ffffcc",
                              color:
                                row.bookingStatus === "Confirm" ||
                                row.bookingStatus === "CONFIRMED"
                                  ? "green"
                                  : "black",
                            }}
                          >
                            {row.bookingStatus || "Pending"}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <Label>
                            {moment(row.updatedAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <Label>
                            <Button
                              variant="contained"
                              onClick={handleClickOpen}
                            >
                              Update
                            </Button>
                          </Label>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                style={{ margin: 2 }}
                component="Table"
                count={PNRInfo?.data?.length || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Scrollbar>
        </Card>
        <UpdatePNRRequest
          handleClose={handleClose}
          openUpdatePnr={openUpdatePnr}
        />
      </div>
    );
  }
};

export default InhouseAgentsPNR;
