import { lazy, Suspense } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import LoadingScreen from "../../../components/LoadingScreen.js";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// component
const HotelListing = Loadable(
  lazy(() => import("../../../pages/hotels/HotelListing.js"))
);
const HotelDetailPage = Loadable(
  lazy(() => import("../../../pages/hotels/HotelDetailPage.js"))
);
const HotelCheckoutPage = Loadable(
  lazy(() => import("../../../pages/hotels/HotelCheckoutPage.js"))
);

export const HotelRoutes = () => ({
  path: "hotel",
  children: [
    {
      path: "",
      element: <Navigate to="/hotel" replace />,
    },
    {
      path: "hotel_result",
      element: <HotelListing />,
    },

    { path: "hotel_result", element: <HotelListing /> },
    { path: "hotel_detail/:detial", element: <HotelDetailPage /> },
    { path: "hotel_checkout/:checkoutId", element: <HotelCheckoutPage /> },
    // { path: "twotravellerinfo", element: <TwoWayTravellersInfo /> },
    // { path: "createBooking", element: <CreateBooking /> },
  ],
});
