import axios from "axios";
import { hostname } from "src/HostName";
export const addMarkupApi = async (apiData) => {
  const { userid, amount, setIsLoading, token } = apiData;
  setIsLoading(true);
  try {
    const options = {
      method: "POST",
      url: `${hostname}add/agent/markup`,
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "insomnia/2023.5.8",
        "x-access-token": token,
      },
      data: { userid: userid, amount: parseInt(amount) },
    };
    const data = await axios.request(options);
    return data;
  } catch (error) {
    console.log(error.message);
  } finally {
    setIsLoading(false);
  }
};
